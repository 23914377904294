<template>
    <div>
        <template>
            <div id="breadcrumb-alignment" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb :items="breadCrumb()" />
                </div>
            </div>
        </template>

        <b-card no-body>
            <b-card-body style="padding-top: 20px !important;">
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <v-select :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sites" label="site_name" class="w-100"
                            placeholder="Project Site" v-model="siteData"
                            @input="siteChange()"
                        >
                            <template #selected-option="{ site_name }">
                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                            </template>
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <b-form-group class="common_margin_bottom_zero">
                            <v-select :clearable="false"
                                v-model="location"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="name" :options="locationList"
                                placeholder="Select Location"
                                @input="dataList()"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" class="mb-md-0 mb- mr-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five" style="padding-top: 0.6% !important; padding-left: 0 !important;">
                        <b-form-group v-slot="{ ariaDescribedby }" class="d-flex ml-1 checkbox-align">
                            <b-form-checkbox
                                v-for="(type, ind) in toilet_types" :key="ind"
                                v-model="type.value"
                                :value="true"
                                :unchecked-value="false"
                                inline
                                @change="updateSelected"
                                :aria-describedby="ariaDescribedby"
                                style="padding-bottom: 5px;"
                            >
                                {{type.title}}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" md="1" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five" v-if="checkPermission($route.name, 'Generate QR')">

                      <b-button                                
                          variant="primary"
                          class="mobile-margin-bottom float-right margin_bottom_zero_mobile"
                          @click="openAssignSiteModel"
                          :disabled="total_selected == 0 ? true : false"
                      >
                          <span class="text-nowrap">Generate QR</span>
                      </b-button>
                    </b-col> -->

                    
                </b-row>

            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">

          <!-- <div class="m-2">
            
            <b-row>

              
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="filterTable"
                />
                <label>entries</label>
              </b-col>

              
              <b-col
                cols="12"
                md="6"
                class="mobile_tab_max_width_flex all_btn_tab"
              >
                <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                    placeholder="Search..."
                    @input="filterTable"
                  />
                  
                </div>
              </b-col>
            </b-row>

          </div> -->

            <!-- Code to list Location -->

            <div class="p-1 px-2">

                <b-row class="py-1">
                    <!-- Per Page -->
                    <b-col cols="12" md="10" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="updatePagination"
                            style="width: 86px;"
                        />
                        <label>entries</label>
                        <label class="label-space" v-if="total_selected > 0"> Total Selected QR - {{ total_selected }} </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="2" class="mobile_tab_max_width_flex">
                        <b-button                                
                          variant="primary"
                          class="mobile-margin-bottom float-right margin_bottom_zero_mobile"
                          @click="openAssignSiteModel"
                          :disabled="total_selected == 0 ? true : false"
                      >
                          <span class="text-nowrap">Generate QR</span>
                      </b-button>
                    </b-col>    
                </b-row>

                <hr>

                <b-row v-if="items.length > 0" class="">

                    <div class="text-center">
                        <div class="card-deck text-center custom-card qrCard" v-for="(item,index) in items_paged" :key="index" style="margin-top: 20px !important; margin-bottom:  0 !important;">

                            <div class="card box-shadow border" :class="item.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(item._id)">
                                
                                <div :class="item.qr_status == 'active' ? 'bg-success' : 'bg-danger'" style="padding: 5px; width: 5px; border-radius: 25px; margin-left: auto; margin-right: 2px;margin-top:2px"/>
                                <div class="w-100 text-center">
                                    <img :src="item.qr_code" variant="light-primary" class="rounded text-center" style="width:70%; padding-top: 0px"/>
                                </div>
                                <p class="text-center mt-2 categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;" :title="item.name.length > 20 ? item.name : ''"> {{item.name | truncate20}} </p> 


                                <div class="business-items qrLocFooter" bis_skin_checked="1">
                                    <div class="business-item" bis_skin_checked="1">
                                        <!-- d-flex  -->
                                        <div class="align-items-center justify-content-between" bis_skin_checked="1">

                                            <!-- <b-link v-b-tooltip.hover.v-warning title="Generate QR Code" variant="outline-warning" v-if="item.qr_generated == 'no'" @click="generateQRCode(item)">
                                                <feather-icon icon="RefreshCwIcon" class="mediumSize ml-1" />    
                                            </b-link>

                                            <b-link v-b-tooltip.hover.v-warning title="Download QR Code" variant="outline-warning" v-if="item.qr_generated != 'no'" @click="downloadItem(item)">
                                                <feather-icon icon="DownloadIcon" class="mediumSize ml-1" />    
                                            </b-link> -->

                                            
                                            <b-form-checkbox
                                                value="active"
                                                unchecked-value="inactive"
                                                class="custom-control-primary"
                                                switch
                                                v-model="item.qr_status"
                                                :key="item._id"
                                                @change="updateStatus(item)"
                                                v-if="checkPermission($route.name, 'Change Status')"
                                            />
                                                
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                    
                            </div>
                        </div>

                    </div>
                    
                </b-row>
                <div v-else>
                    <p class="text-center h5"> No QR Code Found. </p>
                </div>
            </div>

            <hr>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRecords"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="updatePagination"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

        <b-modal
        id="assign_site"
        ref="assign_site"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Generate QR Code"
        @ok="generateQR"
        no-close-on-backdrop
        
    >

        <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{error_message}}
            </div>
        </b-alert>

        <b-form>

            <b-row class="mt-1">
                <b-col md="12">

                  <b-form-group
                      label="Select Theme"  
                      class="required mb-1"
                    >
                    
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="yellow"
                          v-model="theme"
                          @change="resetFile()"
                        >
                          Yellow Theme
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="blue"
                          v-model="theme"
                          @change="resetFile()"
                        >
                          Blue Theme
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="custom"
                          v-model="theme"
                          @change="resetFile()"
                        >
                          Custom Theme
                        </b-form-radio>
                      </div>
                    </b-form-group>
                    <!-- </b-form-group> -->
                </b-col>
            </b-row>
            <b-row class="mt-2" v-if="theme == 'custom'">
                <b-col md="12">
                    <b-form-group
                      label="Upload Template"  
                      class="required mb-1"
                    >
                      <b-form-file
                        id="upload_file"
                        ref="uploadFile"
                        accept=".png"
                        @change="onFileChange"
                        
                      />
                      <small class="text-danger">supported format: .png</small>
                    </b-form-group>
              </b-col>
            </b-row>


            
        </b-form>
    </b-modal>
    </div>

</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BForm, BAlert, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BFormFile, BFormGroup, BFormCheckbox, BCardBody, BBreadcrumb, BFormRadio,BFormRadioGroup
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import moment from "moment-timezone";
    import flatPickr from 'vue-flatpickr-component';

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BForm, vSelect, 
            BAlert, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BFormFile, BFormGroup, BFormCheckbox, BBreadcrumb, flatPickr, BFormRadio,BFormRadioGroup
            
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                showDismissibleAlert:false,
                error_message:null,
                popshowDismissibleAlert:false,
                sites:[],
                siteData:null,
                items: [],
                items_paged : [],
                locationList: [],
                location: '',
                perPageOptions  : [7, 28, 56, 112],
                perPage         : 28,
                totalRecords : 0,
                currentPage : 1,
                searchQuery:'',
                from:0,
                to:0,
                theme:'yellow',
                file: {
                    name:'',
                    image:null
                },
                toilet_types:[
                    {
                        title: 'Male Toilet', key: 'male', value: false
                    }, {
                        title: 'Female Toilet', key: 'female', value: false
                    }, {
                        title: 'Handicap Toilet', key: 'handicapped', value: false
                    }, {
                        title: 'Other', key: 'other', value: false
                    }
                ],
                total_selected : 0               
            }
        },
        methods : {   
            getCount(){
                this.total_selected = this.items.filter(obj => obj.selected === true).length;
            },

            updatePagination(){
                this.items_paged = [];
                this.from = null;
                this.to = null;
                
                for (var i = (this.currentPage - 1) * this.perPage; i < (this.currentPage * this.perPage) && i < this.items.length; i++) {
                    this.items_paged.push(this.items[i]);
                }
                
                this.perPage = this.perPage;
                this.from    = this.items_paged.length > 0 ? (this.perPage * (this.currentPage -1)) + 1 : 0;
                this.to      = (this.perPage * (this.currentPage -1)) + this.items_paged.length;
            },

            updateSelected(){
                this.toilet_types.forEach(el => {
                    this.items.forEach((item, ind) => {
                        if(el.key == item.toilet_type){
                            this.items[ind].selected = el.value;
                        }
                    });
                });

                this.getCount();
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;

                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);
                        
                        this.siteData = this.defaultSite(this.sites);
                        this.allLocations();
                        return this.sites;
                    }
                });
            },

            allLocations(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.siteData ? this.siteData._id : null,
                        type:'toilet'
                    },
                    api: '/api/all-locations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;                
                        this.locationList = data;

                        
                        var obj = {
                            _id:null,
                            name:'All Locations'
                        }
                        this.locationList.unshift(obj);
                        
                        this.location = this.locationList[0]

                        this.dataList();

                        return this.locationList;
                    }
                });
            },

            breadCrumb(){
                var item = [{
                    to:{name:'client-dashboard'},
                    text: 'Dashboard',
                },{
                    to:null,
                    text: 'Feedback System',
                },{
                    to:null,
                    text:'QR Code',
                    active:true
                }];
                return item;
            },

            dataList(){
                return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id : null,
                     location:this.location ? this.location._id : null
                   },
                   api: '/api/feedback-system-qr-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        
                        // this.perPage = this.$store.getters.getResults.data.limit;
                        // this.from = this.$store.getters.getResults.data.from
                        // this.to = this.$store.getters.getResults.data.to
                        // return this.items;

                        this.toilet_types = [
                            {
                                title: 'Male Toilet', key: 'male', value: false
                            }, {
                                title: 'Female Toilet', key: 'female', value: false
                            }, {
                                title: 'Handicap Toilet', key: 'handicapped', value: false
                            },
                            {
                                title: 'Other', key: 'other', value: false
                            }
                        ];
                        
                        this.total_selected = 0;
                        this.updatePagination();
                    }
                });
            },
            filterTable(){
              this.dataList();
            },
            siteChange(){
                this.allLocations();
            },
            updateStatus(item){
      
              return this.$store.dispatch(POST_API, {
                 data:{
                  item:item
                 },
                 api: '/api/update-qr-status'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                  } else {
                    var data = this.$store.getters.getResults;

                    this.items.forEach((el, ind) => {
                        if(el._id == item._id){
                            this.items[ind].selected = false;
                        }
                    });
                    this.getCount();
                  }
              });
            },
            /*generateQRCode(item) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site : item.site,
                        locations : [item],
                        theme:'yellow',
                        toilet_type:"custom",
                        file:{name: "", image: null}
                    },
                    api : '/api/genearte-qr-for-feedback'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.popshowDismissibleAlert = true;
                    } else {
                        this.popshowDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        var message  = this.$store.getters.getResults.message;
                        this.successAlert()
                        .then((result) => {
                            this.dataList();
                            window.open(data,'_blank');
                            
                        });
                    }
                });
            },*/
            generateQR(bvModalEvt){
                bvModalEvt.preventDefault()

                var locations = this.items.filter(item => { return item.selected == true});
                //locations = locations.map(item => { return item._id});

                var api = this.theme == 'custom' ? '/api/genearte-custom-qr-for-feedback2': '/api/genearte-qr-for-feedback2';

                  return this.$store.dispatch(POST_API, {
                      data:{
                          site: this.siteData ? this.siteData._id:null,
                          locations : locations,
                          theme:this.theme,
                          file:this.file
                      },
                      api : api
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.popshowDismissibleAlert = true;
                      } else {
                          this.popshowDismissibleAlert = false;
                          var data  = this.$store.getters.getResults.data;
                          var message  = this.$store.getters.getResults.message;
                          this.successAlert()
                          .then((result) => {
                              
                              window.open(data,'_blank');

                              this.$refs['assign_site'].hide();
                              this.filterTable();

                              this.toilet_types = [
                                    {
                                        title: 'Male Toilet', key: 'male', value: false
                                    }, {
                                        title: 'Female Toilet', key: 'female', value: false
                                    }, {
                                        title: 'Handicap Toilet', key: 'handicapped', value: false
                                    }
                                ];
                              
                          });
                      }
                  });             
               
            },
            downloadItem(item){

                window.open(item.qr_generated+'?'+new Date(), '_blank');
    
            },
            updateSelect(_id){
                this.items.forEach((el, index) => {
                    if(el.qr_status == 'active' && el._id == _id){
                        this.items[index].selected = this.items[index].selected == true ? false : true;
                    }
                });

                this.getCount();
            },
            openAssignSiteModel(){
                this.popshowDismissibleAlert = false;
                this.toilet_type = 'custom';
                this.theme = 'yellow';

                this.file = {
                    name:'',
                    image:null
                };

                this.$refs['assign_site'].show();
            },
            resetFile(){
              this.file = {
                  image : null,
                  name:''
              }

              return this.file;
            },
            onFileChange(event) {
            
              this.file.image = null;
              this.file.name = '';
              var input = event.target;
              
              if (input.files && input.files[0]) {
                  var reader = new FileReader();
                  reader.readAsDataURL(input.files[0]);
                  var file = input.files[0];
                  
                  console.log(file);
                  if (file.type != 'image/png' ) {
                      
                      this.file.file = null;
                      this.file.name = '';
                      
                      
                      this.$refs['uploadFile'].reset()
                      //this.bulkFile = null;

                      this.showDismissibleAlert=false;
                      Swal.fire({
                        icon: 'error',
                        title: "Please upload .png Image",
                      })
                  } else if (file.size > 2097152) {

                      file="";
                      this.file.image = null;
                      this.file.name = '';
                      this.showDismissibleAlert=false;
                      this.$refs['uploadFile'].reset()
                      this.bulkFile = null;

                      Swal.fire({
                        icon: 'error',
                        title: "Maximum 20 MB image are allowed to upload"
                      })
                  } else {
                      reader.onload = (e) => {
                          
                          this.file.image = e.target.result;
                          this.file.name = file.name;

                          this.showDismissibleAlert=false;
                      }
                  }
              }
            },

        },

        mounted(){
            this.allSites();

            
        }    
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    .border-danger {
        border: 2px solid #ea5455 !important;
        padding: 2px !important;
    }
    .border-secondary {
        border: 2px solid rgb(221, 221, 221) !important;
        padding: 2px !important;
    }
    .card-body{
        padding-top: 1rem !important;
    }
    .label-space{
        margin-left: 10px !important;
    }
</style>

<style lang="scss">


    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
